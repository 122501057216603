import React from "react";
import { connect } from "react-redux";

import Content from "../Content";
import EventSummary from "../EventSummary";
import FramedTitle from "../FramedTitle";
import Tile from "../Tile";

import styles from "./styles.module.scss";

export const WhatsOn = ({ past, upcoming }) => (
  <>
    <Content>
      <FramedTitle text="What’s on" />
      <div className={styles.eventsContainer}>
        {upcoming.map((event) => (
          <EventSummary key={event.slug} {...event} />
        ))}
      </div>
      {past.length > 0 && (
        <>
          <Tile text="Past events" isActive={true} />
          <div className={styles.eventsContainer}>
            {past.map((event) => (
              <EventSummary key={event.slug} {...event} past />
            ))}
          </div>
        </>
      )}
    </Content>
  </>
);

const mapStateToProps = ({ events: { past, upcoming } }) => ({
  past,
  upcoming,
});

export default connect(mapStateToProps)(WhatsOn);
