import React, { useEffect } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import classNames from "classnames";

import reducer, { loadAllMenus, loadContents } from "../../reducer";

import DrinkInMenu from "../DrinkInMenu";
import Footer from "../Footer";
import Gallery from "../Gallery";
import Header from "../Header";
import Home from "../Home";
import ScrollToTop from "../ScrollToTop";
import WhatsOn from "../WhatsOn";

import styles from "./styles.module.scss";

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

export default () => {
  useEffect(() => {
    window.ON_PRODUCTION_DOMAIN && ReactGA.initialize("UA-161451416-1");
  }, []);

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const state = document.getElementById("app").getAttribute("state");
  const store = createStore(
    reducer,
    JSON.parse(state),
    composeEnhancers(applyMiddleware(thunk)),
  );

  return (
    <Provider store={store}>
      <Router history={history}>
        <ScrollToTop />
        <TiledBackground>
          <Header />
          <div className={styles.content}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/drinks" exact component={DrinkInMenu} />
              <Route path="/gallery" exact component={Gallery} />
              <Route path="/whats-on" exact component={WhatsOn} />
            </Switch>
          </div>
          <Footer />
        </TiledBackground>
      </Router>
    </Provider>
  );
};

export const TiledBackground = ({ children, storybook = false }) => (
  <div
    className={classNames(styles.container, { [styles.storybook]: storybook })}
  >
    {children}
  </div>
);
