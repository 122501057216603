export const loadingStates = {
  LOADING: "loading",
  LOADED: "loaded",
  FAILED: "failed",
};

const initialState = {
  contents: [],
  events: { past: [], upcoming: [] },
  menus: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
