import React from "react";

import BeerLists from "../BeerLists";
import Content from "../Content";
import FramedTitle from "../FramedTitle";
import Hero from "../Hero";

import hero from "./images/taps.jpg";

export default () => (
  <>
    <Hero src={hero} bottom />
    <Content>
      <FramedTitle text="Drinks" />
      <BeerLists group="drink_in" />
    </Content>
  </>
);
