import React from "react";

import Framed from "../Framed";

import styles from "./styles.module.scss";

export default ({ children, frameStyle, title }) => (
  <div className={styles.container}>
    <Framed style={frameStyle}>
      <div className={styles.contentContainer}>
        <h2 className={styles.h2}>{title}</h2>
        {children}
      </div>
    </Framed>
  </div>
);
