import React from "react";
import classNames from "classnames";

import center from "./images/center.png";
import left from "./images/left.png";
import right from "./images/right.png";

import styles from "./styles.module.scss";

export default ({ onClick, text, isActive, isSmall }) => (
  <div
    className={classNames(styles.container, {
      [styles.isActive]: isActive,
      [styles.isButton]: onClick,
      [styles.isSmall]: isSmall,
    })}
    onClick={onClick}
    data-test-type="Tile"
  >
    <div className={styles.background}>
      <div className={styles.center}>
        <img src={center} />
      </div>
      <div className={styles.left}>
        <img src={left} />
      </div>
      <div className={styles.right}>
        <img src={right} />
      </div>
    </div>
    <div className={styles.textContainer}>{text}</div>
  </div>
);
