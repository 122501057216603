import React from "react";

import AnimatedText from "../AnimatedText";

import styles from "./styles.module.scss";

export default ({
  name,
  brewery,
  style,
  abv,
  description,
  containers = [],
}) => {
  return (
    <div className={styles.beer}>
      <div className={styles.line}>
        <div className={styles.name} data-test-type="BeerName">
          <AnimatedText text={name} />
        </div>
      </div>
      <div className={styles.line}>
        {brewery && <div className={styles.brewery}>{brewery}</div>}
        {style && <div className={styles.style}>{style}</div>}
        {abv && <div className={styles.abv}>{abv}%</div>}
      </div>
      {description && (
        <div className={styles.line}>
          <div className={styles.description}>{description}</div>
        </div>
      )}
      {containers.map((container) => (
        <div className={styles.line}>
          <div className={styles.containerName}>{container.name}</div>
          {container.price && (
            <>
              <div className={styles.dots} />
              <div className={styles.containerPrice}>£{container.price}</div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};
