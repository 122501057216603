import React from "react";
import { connect } from "react-redux";

import Content from "../Content";
import Hero from "../Hero";
import Markdown from "../Markdown";
import Notice from "../Notice";

import hero from "./images/pub.jpg";

import styles from "./styles.module.scss";

const Home = ({ homepageNotice }) => (
  <>
    <Hero src={hero} />
    <Content>
      <div className={styles.framedContainer}>
        <Notice title="Hi there!">
          <Markdown>{homepageNotice}</Markdown>
        </Notice>
      </div>
    </Content>
  </>
);

const mapStateToProps = ({ contents: { homepageNotice } }) => ({
  homepageNotice,
});

export default connect(mapStateToProps)(Home);
