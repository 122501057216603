import React from "react";
import classNames from "classnames";
import Framed from "../Framed";
import styles from "./styles.module.scss";

export default ({ text, light, withSpacing }) => (
  <div
    className={classNames(styles.container, {
      [styles.withSpacing]: withSpacing,
    })}
  >
    <Framed>
      <div className={styles.innerContainer}>
        <div
          className={classNames(styles.letterBoard, { [styles.light]: light })}
        />
        <h1 className={classNames(styles.h1, { [styles.light]: light })}>
          {text}
        </h1>
      </div>
    </Framed>
  </div>
);
