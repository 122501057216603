import React from "react";

import Framed from "../Framed";

import styles from "./styles.module.scss";

export default ({ image, largeImage }) => (
  <Framed style={"brown"}>
    <img src={image} className={styles.image} />
  </Framed>
);
