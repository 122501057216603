import React, { useState } from "react";
import { Link, NavLink } from "../TrackedLink";

import Logo from "../Logo";

import hamburger from "./images/hamburger.svg";

import styles from "./styles.module.scss";

const SECTIONS = [
  { label: "Home", to: "/" },
  { label: "Drinks", to: "/drinks" },
  { label: "What’s On", to: "/whats-on" },
  { label: "Gallery", to: "/gallery" },
];

export default () => {
  const [isExpandedNavVisible, setIsExpandedNavVisible] = useState(false);

  const toggleNavVisibility = () =>
    setIsExpandedNavVisible(!isExpandedNavVisible);

  return (
    <div className={styles.wrapper}>
      <header className={styles.container}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className={styles.hamburger}>
          <a onClick={toggleNavVisibility}>
            <img src={hamburger} />
          </a>
        </div>
        <nav className={isExpandedNavVisible ? styles.expanded : ""}>
          <ul>
            {SECTIONS.map(({ label, to }) => (
              <li key={label}>
                <NavLink
                  className={styles.navItem}
                  activeClassName={styles.active}
                  to={to}
                  exact
                  onClick={() => setIsExpandedNavVisible(false)}
                >
                  {label}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </header>
    </div>
  );
};
