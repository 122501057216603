import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import BeerList from "../BeerList";
import Tile from "../Tile";

import styles from "./styles.module.scss";

const BeerLists = ({ menus }) => {
  const [currentMenu, setCurrentMenu] = useState(menus[0]);
  useEffect(() => {
    menus && menus.length && setCurrentMenu(menus[0]);
  }, [menus]);

  return (
    <>
      {menus.length > 1 && (
        <div className={styles.buttonsContainer}>
          <div>
            {menus.map((menu) => (
              <Tile
                onClick={() => setCurrentMenu(menu)}
                text={menu.name}
                isActive={
                  currentMenu && currentMenu.untappdId === menu.untappdId
                }
                key={menu.untappdId}
              />
            ))}
          </div>
        </div>
      )}
      <BeerList menu={currentMenu} />
    </>
  );
};

const mapStateToProps = ({ menus }, { group }) => ({
  menus: menus.filter((menu) => menu.groups.indexOf(group) > -1),
});

export default connect(mapStateToProps)(BeerLists);
