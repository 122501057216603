import React from "react";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";

import { niceDateWithYear, niceTime } from "../../helpers/datetime";

import Framed from "../Framed";

import styles from "./styles.module.scss";

export default ({ description, image, past, price, startsAt, title }) => (
  <div className={classNames(styles.eventContainer, { [styles.past]: past })}>
    <div className={styles.panelContainer}>
      <h2 data-test-type="EventTitle">{title}</h2>
      <div className={styles.info}>
        <div data-test-type="EventDate">{niceDateWithYear(startsAt)}</div>
        <div data-test-type="EventTime">{niceTime(startsAt)}</div>
        {price}
      </div>
      <div className={styles.description}>
        <ReactMarkdown>{description}</ReactMarkdown>
      </div>
    </div>
    {image && (
      <div className={styles.imageContainer}>
        <Framed style="brown">
          <img src={image} className={styles.eventImage} />
        </Framed>
      </div>
    )}
  </div>
);
