import React from "react";
import { render } from "react-dom";

import Honeybadger from "@honeybadger-io/js";
import ErrorBoundary from "@honeybadger-io/react";

import App from "../components/App";

Honeybadger.configure({
  apiKey: window.HONEYBADGER_API_KEY,
  environment: window.ON_PRODUCTION_DOMAIN ? "production" : "staging",
});

document.addEventListener("DOMContentLoaded", () => {
  render(
    <ErrorBoundary honeybadger={Honeybadger}>
      <App />
    </ErrorBoundary>,
    document.getElementById("app"),
  );
});
