import React, { useState } from "react";
import ReactGA from "react-ga";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Content from "../Content";
import FramedTitle from "../FramedTitle";
import GalleryImage from "../GalleryImage";
import Hero from "../Hero";

import hero from "./images/gallery-header.jpg";

import styles from "./styles.module.scss";

const images = [...Array(38).keys()].map((x) => ({
  small: `/images/small/rusty-bucket-${x}.jpg`,
  large: `/images/rusty-bucket-${x}.jpg`,
}));

export default () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openLightbox = (imageIndex) => {
    ReactGA.event({
      category: "Click",
      action: "GalleryImage",
      label: images[imageIndex].large,
    });
    setCurrentImageIndex(imageIndex);
    setIsViewerOpen(true);
  };

  const closeLightbox = () => setIsViewerOpen(false);

  const nextImageIndex = () => (currentImageIndex + 1) % images.length;
  const prevImageIndex = () =>
    (currentImageIndex + images.length - 1) % images.length;
  const imageAt = (index) => images[index].large;

  return (
    <>
      <Hero src={hero} center />
      <Content>
        <FramedTitle text="Gallery" />
        <div className={styles.container}>
          {images.map(({ small }, imageIndex) => (
            <div
              className={styles.galleryImage}
              onClick={() => openLightbox(imageIndex)}
              key={small}
            >
              <GalleryImage image={small} />
            </div>
          ))}
        </div>
      </Content>
      {isViewerOpen && (
        <Lightbox
          mainSrc={imageAt(currentImageIndex)}
          nextSrc={imageAt(nextImageIndex())}
          prevSrc={imageAt(prevImageIndex())}
          onMoveNextRequest={() => setCurrentImageIndex(nextImageIndex())}
          onMovePrevRequest={() => setCurrentImageIndex(prevImageIndex())}
          onCloseRequest={closeLightbox}
        />
      )}
    </>
  );
};
